<template>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent">
        <div class="container-fluid">
            <div class="navbar-wrapper">
                <a class="navbar-brand pt-5" href="">Weather</a>
            </div>

        </div>
    </nav>
    <!-- End Navbar -->
</template>

<script>
    export default {
        name: "Navbar"
    }
</script>

<style scoped>

</style>
