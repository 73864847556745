require('./bootstrap');
import Vue from "vue";
import VueRouter from "vue-router";
import MainContainer from './MainContainer';
import router from './vue-router';

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(far, fas, fab);

Vue.use(VueRouter);
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* eslint-disable no-new */
new Vue({
    el: '.wrapper',
    components: {
        MainContainer,
    },
    router
});
