<template>
    <router-view></router-view>
</template>

<script>
  export default {
    name: "MainContainer",
  }
</script>

<style scoped>
    .btn-to-top {
        width: 60px;
        height: 60px;
        padding: 10px 16px;
        border-radius: 50%;
        font-size: 22px;
        line-height: 22px;
    }
</style>
