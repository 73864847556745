import VueRouter from 'vue-router'
import HomeView from "./views/HomeView";
import NotFoundView from "./views/NotFoundView";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'view.home',
            component: HomeView
        },
        {
            path: "*",
            component: NotFoundView
        }
    ],
});

export default router;
