<template>
  <div class="contact-us full-screen">
    <Navbar/>
    <div class="wrapper wrapper-full-page section content">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-md-offset-2 text-center">
              <h2 class="title text-danger">404 Not Found</h2>
              <h2 class="title">Oops! It seems that this page does not exist.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../components/Commons/Footer";
import Navbar from "../components/Commons/Navbar";
export default {
    components: {Navbar, Footer}
};
</script>
