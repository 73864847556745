<template>
    <div class="main-panel">
        <Navbar/>
        <div class="content">
            <div class="row">
                <div class="col-lg-4">
                    <card type="chart">
                        <template slot="header">
                            <h2 class="card-title dflex">
                                <span class="flex-grow-1">Temperature</span>
                                <font-awesome-icon icon="thermometer-full"></font-awesome-icon>
                                <span class="ml-2">{{ temperatureLineChart.chartData.datasets[0].data[temperatureLineChart.chartData.datasets[0].data.length - 1] }} °C</span>
                            </h2>
                            <h5 class="card-category">Last 10 readings</h5>
                        </template>
                        <div class="chart-area">
                            <line-chart v-if="lastDataReady"
                                        style="height: 100%"
                                        chart-id="temperature-line-chart"
                                        :chart-data="temperatureLineChart.chartData"
                                        :gradient-colors="temperatureLineChart.gradientColors"
                                        :gradient-stops="temperatureLineChart.gradientStops"
                                        :extra-options="temperatureLineChart.extraOptions">
                            </line-chart>
                        </div>
                    </card>
                </div>
                <div class="col-lg-4">
                    <card type="chart">
                        <template slot="header">
                            <h2 class="card-title dflex">
                                <span class="flex-grow-1">Pressure</span>
                                <font-awesome-icon icon="wind"></font-awesome-icon>
                                <span class="ml-2">{{ pressureLineChart.chartData.datasets[0].data[pressureLineChart.chartData.datasets[0].data.length - 1] }} hPa</span>
                            </h2>
                            <h5 class="card-category">Last 10 readings</h5>
                        </template>
                        <div class="chart-area">
                            <line-chart v-if="lastDataReady"
                                        style="height: 100%"
                                        chart-id="pressure-line-chart"
                                        :chart-data="pressureLineChart.chartData"
                                        :gradient-colors="pressureLineChart.gradientColors"
                                        :gradient-stops="pressureLineChart.gradientStops"
                                        :extra-options="pressureLineChart.extraOptions">
                            </line-chart>
                        </div>
                    </card>
                </div>
                <div class="col-lg-4">
                    <card type="chart">
                        <template slot="header">
                            <h2 class="card-title dflex">
                                <span class="flex-grow-1">Humidity</span>
                                <font-awesome-icon icon="tint"></font-awesome-icon>
                                <span class="ml-2">{{ humidityLineChart.chartData.datasets[0].data[humidityLineChart.chartData.datasets[0].data.length - 1] }} %</span>
                            </h2>
                            <h5 class="card-category">Last 10 readings</h5>
                        </template>
                        <div class="chart-area">
                            <line-chart v-if="lastDataReady"
                                        style="height: 100%"
                                        chart-id="humidity-line-chart"
                                        :chart-data="humidityLineChart.chartData"
                                        :gradient-colors="humidityLineChart.gradientColors"
                                        :gradient-stops="humidityLineChart.gradientStops"
                                        :extra-options="humidityLineChart.extraOptions">
                            </line-chart>
                        </div>
                    </card>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <card type="chart">
                        <template slot="header">
                            <h2 class="card-title">
                                Temperature in the last 7 days
                            </h2>
                            <h5 class="card-category">Minimum and maximum values</h5>
                        </template>
                        <div class="chart-area">
                            <line-chart v-if="weekDataReady"
                                        style="height: 100%"
                                        chart-id="week-temperature-line-chart"
                                        :chart-data="weekTemperatureLineChart.chartData"
                                        :gradient-colors="weekTemperatureLineChart.gradientColors"
                                        :gradient-stops="weekTemperatureLineChart.gradientStops"
                                        :extra-options="weekTemperatureLineChart.extraOptions">
                            </line-chart>
                        </div>
                    </card>
                </div>

                <div class="col-lg-4">
                    <card type="chart">
                        <template slot="header">
                            <h2 class="card-title">
                                Pressure in the last 7 days
                            </h2>
                            <h5 class="card-category">Minimum and maximum values</h5>
                        </template>
                        <div class="chart-area">
                            <line-chart v-if="weekDataReady"
                                        style="height: 100%"
                                        chart-id="week-pressure-line-chart"
                                        :chart-data="weekPressureLineChart.chartData"
                                        :gradient-colors="weekPressureLineChart.gradientColors"
                                        :gradient-stops="weekPressureLineChart.gradientStops"
                                        :extra-options="weekPressureLineChart.extraOptions">
                            </line-chart>
                        </div>
                    </card>
                </div>

                <div class="col-lg-4">
                    <card type="chart">
                        <template slot="header">
                            <h2 class="card-title">
                                Humidity in the last 7 days
                            </h2>
                            <h5 class="card-category">Minimum and maximum values</h5>
                        </template>
                        <div class="chart-area">
                            <line-chart v-if="weekDataReady"
                                        style="height: 100%"
                                        chart-id="week-humidity-line-chart"
                                        :chart-data="weekHumidityLineChart.chartData"
                                        :gradient-colors="weekHumidityLineChart.gradientColors"
                                        :gradient-stops="weekHumidityLineChart.gradientStops"
                                        :extra-options="weekHumidityLineChart.extraOptions">
                            </line-chart>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
    import LineChart from '../components/Charts/LineChart';
    import BarChart from '../components/Charts/BarChart';
    import Card from '../components/Cards/Card';
    import * as chartConfigs from '../components/Charts/config';
    import config from '../config';
    import Navbar from "../components/Commons/Navbar";
    import Footer from "../components/Commons/Footer";

    export default {
        components: {
            Footer,
            Card,
            Navbar,
            LineChart,
            BarChart
        },
        data() {
            return {
                temperatureLineChart: {
                    extraOptions: chartConfigs.chartOptions,
                    chartData: {
                        labels: [],
                        datasets: [{
                            label: "Temperature",
                            fill: true,
                            borderColor: config.colors.danger,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: config.colors.danger,
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: config.colors.danger,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: [],
                        }]
                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0.2, 0],
                },
                pressureLineChart: {
                    extraOptions: chartConfigs.chartOptions,
                    chartData: {
                        labels: [],
                        datasets: [{
                            label: "Pressure",
                            fill: true,
                            borderColor: config.colors.primary,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: config.colors.primary,
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: config.colors.primary,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: [],
                        }]
                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0.2, 0],
                },
                humidityLineChart: {
                    extraOptions: chartConfigs.chartOptions,
                    chartData: {
                        labels: [],
                        datasets: [{
                            label: "Humidity",
                            fill: true,
                            borderColor: config.colors.info,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: config.colors.info,
                            pointBorderColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: config.colors.info,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: [],
                        }]
                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0.2, 0],
                },

                weekTemperatureLineChart: {
                    extraOptions: chartConfigs.chartOptions,
                    chartData: {
                        labels: [],
                        datasets: [
                            {
                                label: "Min",
                                fill: true,
                                borderColor: config.colors.info,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.info,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.info,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            },
                            {
                                label: "Max",
                                fill: true,
                                borderColor: config.colors.danger,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.danger,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.danger,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            },
                            {
                                label: "Avg",
                                fill: true,
                                borderColor: config.colors.white,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.white,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.white,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            }
                        ]
                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0.2, 0],
                },

                weekPressureLineChart: {
                    extraOptions: chartConfigs.chartOptions,
                    chartData: {
                        labels: [],
                        datasets: [
                            {
                                label: "Min",
                                fill: true,
                                borderColor: config.colors.info,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.info,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.info,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            },
                            {
                                label: "Max",
                                fill: true,
                                borderColor: config.colors.danger,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.danger,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.danger,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            },
                            {
                                label: "Avg",
                                fill: true,
                                borderColor: config.colors.white,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.white,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.white,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            }
                        ]
                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0.2, 0],
                },

                weekHumidityLineChart: {
                    extraOptions: chartConfigs.chartOptions,
                    chartData: {
                        labels: [],
                        datasets: [
                            {
                                label: "Min",
                                fill: true,
                                borderColor: config.colors.info,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.info,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.info,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            },
                            {
                                label: "Max",
                                fill: true,
                                borderColor: config.colors.danger,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.danger,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.danger,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            },
                            {
                                label: "Avg",
                                fill: true,
                                borderColor: config.colors.white,
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: config.colors.white,
                                pointBorderColor: 'rgba(255,255,255,0)',
                                pointHoverBackgroundColor: config.colors.white,
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: [],
                            }
                        ]
                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0.2, 0],
                },

                lastDataReady: false,
                weekDataReady: false,
            }
        },
        mounted() {
            let self = this;

            axios
                .get(route('api.detections.last') + '/10')
                .then(response => {
                    let labels = _.map(response.data, 'created_at');

                    self.temperatureLineChart.chartData.labels = labels;
                    self.temperatureLineChart.chartData.datasets[0].data = _.map(response.data, 'temperature');
                    self.pressureLineChart.chartData.labels = labels;
                    self.pressureLineChart.chartData.datasets[0].data = _.map(response.data, 'pressure');
                    self.humidityLineChart.chartData.labels = labels;
                    self.humidityLineChart.chartData.datasets[0].data = _.map(response.data, 'humidity');

                    self.lastDataReady = true;
                });

            axios
                .get(route('api.detections.days') + '/7')
                .then(response => {

                    let labels = _.map(response.data, 'date');

                    self.weekTemperatureLineChart.chartData.labels = labels;
                    self.weekPressureLineChart.chartData.labels = labels;
                    self.weekHumidityLineChart.chartData.labels = labels;


                    self.weekTemperatureLineChart.chartData.datasets[0].data = _.map(response.data, 'min_temp');
                    self.weekTemperatureLineChart.chartData.datasets[1].data = _.map(response.data, 'max_temp');
                    self.weekTemperatureLineChart.chartData.datasets[2].data = _.map(response.data, 'avg_temp');

                    self.weekPressureLineChart.chartData.datasets[0].data = _.map(response.data, 'min_pressure');
                    self.weekPressureLineChart.chartData.datasets[1].data = _.map(response.data, 'max_pressure');
                    self.weekPressureLineChart.chartData.datasets[2].data = _.map(response.data, 'avg_pressure');

                    self.weekHumidityLineChart.chartData.datasets[0].data = _.map(response.data, 'min_humidity');
                    self.weekHumidityLineChart.chartData.datasets[1].data = _.map(response.data, 'max_humidity');
                    self.weekHumidityLineChart.chartData.datasets[2].data = _.map(response.data, 'avg_humidity');

                    self.weekDataReady = true;
                });
        },
    };
</script>
<style>
</style>
