<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="copyright">
                © Weather {{ year }} made with <font-awesome-icon icon="heart"></font-awesome-icon> by
                <a href="https://www.angelocassano.it" target="_blank">Angelo Cassano</a>.
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        computed: {
            year: () => new Date().getFullYear()
        }
    }
</script>

<style scoped>

</style>
